<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <theme-picker /> -->
  </div>
</template>

<script>
// import ThemePicker from "@/components/ThemePicker";
import wujieVue from "wujie-vue2";
import router from './router';
import store from './store';
import { getToken } from '@/utils/auth'
export default {
  name: "App",
  // components: { ThemePicker },
    metaInfo() {
        return {
            // title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
            // titleTemplate: title => {
            //     return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
            // }
        }
    },
    watch: {
      // 在 vue3-sub 路由下主动告知主应用路由跳转，主应用也跳到相应路由高亮菜单栏
      // $route() {
        // if(this.$route.path === '/vms/driveroilsubmit'){
          // setTimeout(()=>{
          //   wujieVue.bus.$emit("sub-route-change2", this.$route.path);
          //   wujieVue.bus.$emit("sub-route-change3", this.$route.path);
          // },3000)
        // }
      // },
    },
    mounted(){
      if(getToken()){
        store.dispatch('GenerateRoutes').then(accessRoutes => {
            console.log("GenerateRoutes",accessRoutes)
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        })
      }
    },
};
</script>
<style>
#app .theme-picker {
  display: none;
}

::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-track{
	background: #dbdbdb;
	border-radius: 3px;
}
::-webkit-scrollbar-thumb {
	background: #b1b0b3;
	border-radius: 3px;
}
</style>
